<template>
    <VLayoutMiddle>
        <h1>Require to change your password.</h1>
        <p>
            Please change your password to make your account more secure.
        </p>
        <PasswordResetForm />
    </VLayoutMiddle>
</template>
<script>
import PasswordResetForm from '@/components/PasswordResetForm.vue'
import VLayoutMiddle from '@/components/VLayoutMiddle.vue'

export default {
    components: {
        PasswordResetForm,
        VLayoutMiddle,
    },
}
</script>
