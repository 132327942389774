var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("VLayoutMiddle", [
    _c("h1", [_vm._v("Forgot Password?")]),
    _c("br"),
    _c("h3", [_vm._v("\n        Contact Us (Workhour: 8:00-20:00)\n    ")]),
    _c("strong", [_vm._v("Tel: ")]),
    _vm._v("088-888-8888\n    "),
    _c("br"),
    _c("strong", [_vm._v("Email: ")]),
    _vm._v("lenkilateam@gmail.com\n    "),
    _c("br"),
    _c("strong", [_vm._v("Facebook: ")]),
    _c("a", { attrs: { href: "https://www.facebook.com/lenkila" } }, [
      _vm._v("https://www.facebook.com/lenkila")
    ]),
    _c("br"),
    _c("br"),
    _c("br"),
    _c("br"),
    _c(
      "a",
      { staticClass: "forgot-password-form__login", attrs: { href: "/login" } },
      [_vm._v("Back to Login")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }