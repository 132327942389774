<template>
    <VLayoutMiddle>
        <h1>Forgot Password?</h1>
        <br>
        <h3>
            Contact Us (Workhour: 8:00-20:00)
        </h3>
        <strong>Tel: </strong>088-888-8888
        <br>
        <strong>Email: </strong>lenkilateam@gmail.com
        <br>
        <strong>Facebook: </strong>  <a href="https://www.facebook.com/lenkila">https://www.facebook.com/lenkila</a>
        <br>
        <br>
        <br>
        <br>
        <a class="forgot-password-form__login" href="/login">Back to Login</a>
    </VLayoutMiddle>
</template>

<script>
import VLayoutMiddle from '@/components/VLayoutMiddle.vue'

export default {
    components: {
        VLayoutMiddle,
    },
}
</script>
