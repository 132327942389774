<template>
    <a-form
        class="reset-password-form"
        :form="form"
        @submit="handleSubmit">
        <a-alert
            v-if="responseMsg"
            class="reset-password-form__info-msg"
            :type="messageType"
            :message="responseMsg"
            banner="banner" />
        <PasswordResetFormItem :form="form" />
        <a-form-item>
            <a-button
                type="primary"
                html-type="submit"
                :loading="status == 'processing'">
                บันทึกรหัสผ่านใหม่
            </a-button>
        </a-form-item>
    </a-form>
</template>
<script>
import { rootActions } from '@/store/types'
import PasswordResetFormItem from '@/components/PasswordResetFormItem.vue'

export default {
    components: {
        PasswordResetFormItem,
    },
    props: {
        token: {
            type: String,
            default: '',
        },
        userId: {
            type: String,
            default: '',
        },
    },
    data () {
        return {
            form: this.$form.createForm(this),
            responseMsg: null,
            status: null,
        }
    },
    computed: {
        messageType () {
            if (this.status === 'success') return 'success'
            if (this.status === 'error') return 'error'
            return 'warning'
        },
    },
    watch: {
        status (newStatus) {
            if (newStatus === 'success') {
                this.showSuccessModal()
            }
        },
    },
    methods: {
        handleSubmit (e) {
            e.preventDefault()
            this.form.validateFields(async (err, values) => {
                if (!err) {
                    const res = await this.$store.dispatch(
                        rootActions.RESET_PASSWORD, values.password,
                    )
                    if (res.isSuccess()) {
                        await this.$store.dispatch(
                            rootActions.LOGOUT,
                        )
                    }
                    this.status = res.status
                    this.responseMsg = res.msg
                }
            })
        },
        compareToFirstPassword (rule, value, callback) {
            const { form } = this
            if (value && value !== form.getFieldValue('password')) {
                callback('ค่าใส่ช่อง ยืนยัน รหัสผ่านใหม่ ไม่ตรงกับ ช่องรหัสผ่านใหม่ กรุณาแก้ไขให้ตรงกันด้วยค่ะ')
            } else {
                callback()
            }
        },
        showSuccessModal () {
            const router = this.$router
            this.$success({
                title: 'สร้างรหัสผ่านใหม่สำเร็จ',
                content: (
                    <div>
                        <p>คุณสามารถเข้าระบบด้วยรหัสผ่านใหม่ได้แล้วค่ะ</p>
                    </div>
                ),
                centered: true,
                onOk () {
                    router.push({ name: 'login' })
                },
            })
        },
    },
}
</script>
<style lang="stylus">
.reset-password-form
    margin: 0 auto

.reset-password-form__info-msg
    margin-bottom: 16px

.reset-password-form__login
    margin-left: 16px
</style>
