var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-form",
    {
      staticClass: "reset-password-form",
      attrs: { form: _vm.form },
      on: { submit: _vm.handleSubmit }
    },
    [
      _vm.responseMsg
        ? _c("a-alert", {
            staticClass: "reset-password-form__info-msg",
            attrs: {
              type: _vm.messageType,
              message: _vm.responseMsg,
              banner: "banner"
            }
          })
        : _vm._e(),
      _c("PasswordResetFormItem", { attrs: { form: _vm.form } }),
      _c(
        "a-form-item",
        [
          _c(
            "a-button",
            {
              attrs: {
                type: "primary",
                "html-type": "submit",
                loading: _vm.status == "processing"
              }
            },
            [_vm._v("\n            บันทึกรหัสผ่านใหม่\n        ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }