import { isEqual, removeUndefinedKeyOnObj } from '@/utils/utils'

export default {
    props: {
        form: {
            required: true,
            type: Object,
        },
        data: {
            type: Object,
            default: null,
        },
    },
    computed: {
        disabled () {
            if (!this.form) return false
            const data = this.form._data
            return data ? data.disabled : false
        },
    },
    mounted () {
        this.loadDataToFormItems(this.data)
    },
    watch: {
        data (newValue, oldValue) {
            if (!isEqual(oldValue, newValue)) this.loadDataToFormItems(newValue)
        },
    },
    methods: {
        isNotEmptyData (data) {
            return data && Object.keys(data).length !== 0
        },
        isEmptyData () {
            return !this.isNotEmptyData(this.data)
        },
        loadDataToFormItems (data) {
            if (this.isNotEmptyData(data)) {
                const fieldValues = removeUndefinedKeyOnObj(this.mapDataToFormItems(data))
                this.form.setFieldsValue(fieldValues)
                this.afterLoadDataToFormItems()
            }
        },
        // eslint-disable-next-line no-unused-vars
        mapDataToFormItems (data) {
            if (process.env.NODE_ENV !== 'production') {
                // eslint-disable-next-line no-console
                console.error(('[mixins:FormItems] Please overide mapDataToFormItems() method.'))
            }
        },
        afterLoadDataToFormItems () {
            // implement operations which will process after load data.
        },
        // eslint-disable-next-line no-unused-vars
        mapFormItemsToData (data) {
            if (process.env.NODE_ENV !== 'production') {
                // eslint-disable-next-line no-console
                console.error(('[mixins:FormItems] Please overide mapFormItemsToData() method.'))
            }
        },
    },
}
