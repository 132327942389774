var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VLayoutMiddle",
    [
      _c("h1", [_vm._v("Require to change your password.")]),
      _c("p", [
        _vm._v(
          "\n        Please change your password to make your account more secure.\n    "
        )
      ]),
      _c("PasswordResetForm")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }